import { IOrderItemDisplay } from "./orderItem";
import { IAddress } from "./address";

export interface IPayType {
  type: string;
  value: number;
}
export interface IOrderSubmit {
  cartId: string;
  orderType: RegisterType;
  customerId?: string;
  addressId?: string;
}
export interface IOrderDisplay {
  id: string;
  orderTotal: number;
  discount: number;
  deliveryCharge: number;
  billingAddressId: string;
  orderStatusId: string;
  orderStatusName: string;
  shippingStatusId: string;
  shippingStatusName: string;
  paymentStatusId: string;
  paymentStatusName: string;
  orderItems: IOrderItemDisplay[];
  serviceCharge: number;
  customOrderNumber: string;
}
export interface IOrderEdit {
  id: string;
  addressId?: string;
  payType: string;
  payPalOrderId?: string;
  wPayToken?: string;
  note: string;
  deliveryTime: Date | null;
}

export interface IOrderList {
  id: string;
  orderGuid: string;
  billingAddressId: string;
  orderStatusId: string;
  orderStatusName: string;
  shippingStatusId: string;
  shippingStatusName: string;
  paymentStatusId: string;
  paymentStatusName: string;
  discount: number;
  orderTotal: number;
  refundedAmount: number;
  deliveryCharge: number;
  shippingMethod: string;
  customOrderNumber: string;
  createdOn: Date;
}
export interface IOrderSearch {
  fullName: string;
  paymentStatusId: string;
  orderStatusId: string;
  total: number;
  customOrderNumber: string;
}
export interface IOrdersList {
  id: string;
  fullName: string;
  orderStatusId: string;
  orderStatusName: string;
  shippingStatusId: string;
  paymentStatusId: string;
  paymentStatusName: string;
  total: number;
  createdOn: Date;
  customOrderNumber: string;
  address: string;
  pickupInStore: boolean;
  deleted: boolean;
}

export interface IOrderAdminList {
  id: string;
  orderGuid: string;
  customerName: string;
  orderStatusId: string;
  shippingStatusId: string;
  paymentStatusId: string;
  paymentStatusName: string;
  discount: number;
  orderTotal: number;
  deliveryCharge: number;
  createdOn: Date;
  customOrderNumber: string;
}

export interface IOrderDetail {
  id: string;
  billingAddress: IAddress;
  orderItems: IOrderItemDisplay[];
  orderGuid: string;
  billingAddressId: string;
  orderStatusId: string;
  orderStatusName: string;
  shippingStatusId: string;
  shippingStatusName: string;
  paymentStatusId: string;
  paymentStatusName: string;
  discount: number;
  orderTotal: number;
  total: number;
  refundedAmount: number;
  deliveryCharge: number;
  shippingMethod: string;
  customOrderNumber: string;
  createdOn: Date;
  payType: string;
  pickupInStore: boolean;
  deliveryTime: string | null;
  serviceCharge: number;
}

export interface IChangeStatus {
  id: string;
  orderStatusId: string;
  shippingStatusId: string;
  paymentStatusId: string;
}

export interface IOrderEntry {
  rowid: string;
  categories: [];
}

export interface IReorder {
  orderId: string;
}

export interface ITableOrder {
  tableNumber: number;
  numberOfPeople: number;
  cartId: string;
  notes: string;
  orderType: RegisterType;
}

export interface ITableOrderRes {
  firstName: string;
  guest: boolean;
  lastName: string;
  orderId: string;
  tableOrder: boolean;
  token: string;
}

export interface IApplyVoucherDiscount {
  orderId: string;
  voucherCode: string;
}

export enum RegisterType {
  Web = "Web",
  OrderEntry = "OrderEntry",
  TableOrder = "TableOrder",
  GuestOrder = "GuestOrder",
  Kiosk = "Kiosk",
}
